import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import eventInfoHelper from 'HorseRacingRacecard/helpers/event_info_helper';
import { identity } from 'underscore';
import { t } from 'bv-i18n';

const infoItemsMapping = [
  {
    prop: 'eventInfo',
    title: t('javascript.race_overview.distance'),
    helper: eventInfoHelper,
  },
  {
    prop: 'eWTerms',
    title: t('javascript.race_overview.ew'),
    helper: identity,
  },
  {
    prop: 'going',
    title: t('javascript.race_overview.ground'),
    helper: identity,
  },
];

const InfoItem = ({ title, value }) => (
  <div className="racecard-tf-infoitem">
    <div className="racecard-tf-infoitem-title">{title}</div>
    { value && <div className="racecard-tf-infoitem-value" dangerouslySetInnerHTML={{ __html: sanitize(value) }} /> }
  </div>
);

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const RaceHeader = ({ raceTitleShort, raceName, raceClass }) => {
  const nameText = raceTitleShort || raceName;
  const classText = (raceClass && ` - ${t('javascript.race_class')} ${raceClass}`) || '';

  return (
    <div className="racecard-tf-racename">
      {[nameText, classText].join('')}
    </div>
  );
};

RaceHeader.propTypes = {
  raceTitleShort: PropTypes.string.isRequired,
  raceName: PropTypes.string.isRequired,
  raceClass: PropTypes.string.isRequired,
};

/* eslint-disable react/destructuring-assignment */
const GeneralInfo = (props) => {
  const { raceTitleShort, raceName, raceClass } = props;

  return (
    <div>
      <div className="racecard-tf-infoitem-wrapper">
        {
          infoItemsMapping
            .filter((infoItem) => !!props[infoItem.prop])
            .map((infoItem) => (
              <InfoItem
                title={infoItem.title}
                value={infoItem.helper(props[infoItem.prop])}
              />
            ))
            .reduce((acc, divItem) => (acc === null ? [divItem] : [...acc, divItem]), null)
        }
      </div>
      <RaceHeader
        raceTitleShort={raceTitleShort}
        raceName={raceName}
        raceClass={raceClass}
      />
    </div>
  );
};

GeneralInfo.propTypes = {
  raceTitleShort: PropTypes.string.isRequired,
  raceClass: PropTypes.string.isRequired,
  raceName: PropTypes.string.isRequired,
};

export default GeneralInfo;
